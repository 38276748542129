<template>
  <div class="visualizer-wrapper">
    <div class="visualizer-container" :class="{ 'is-playing': isPlaying }">
      <div class="top-controls">
        <div class="progress-bar" v-if="duration > 0">
          <div 
            class="progress-track" 
            ref="progressTrack"
          >
            <div 
              class="progress-fill" 
              :style="{ width: `${(currentTime / actualDuration) * 100}%` }"
            ></div>
          </div>
        </div>

        <div class="time-display">
          <span>{{ formatTime(currentTime) }} / {{ formatTime(actualDuration) }}</span>
        </div>

        <div class="style-selector">
          <select v-model="currentStyle">
            <option v-for="style in visualStyles" :key="style.value" :value="style.value">
              {{ style.label }}
            </option>
          </select>
        </div>
      </div>
      <canvas ref="canvas" class="visualizer"></canvas>
    </div>
  </div>
</template>

<style scoped>
.visualizer-wrapper {
  width: 100%;
  overflow: hidden;
}

.visualizer-container {
  width: 100%;
  height: 180px;
  position: relative;
  background-color: rgba(15, 23, 42, 0.95);
  margin-top: 40px;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  border: 1px solid rgba(34, 211, 238, 0.1);
  box-shadow: 0 4px 20px rgba(34, 211, 238, 0.1);
}

:deep(.el-main) {
  overflow-x: hidden;
}

.visualizer-container.is-playing {
  background-color: rgba(15, 23, 42, 0.85);
  border-color: rgba(34, 211, 238, 0.2);
  box-shadow: 0 4px 30px rgba(34, 211, 238, 0.2);
  transform: translateY(-1px);
}

.top-controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px;
  gap: 20px;
  z-index: 2;
  margin-top: 15px;
  position: relative;
}

.style-selector {
  min-width: 100px;
}

.style-selector select {
  background: rgba(255, 255, 255, 0.1);
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  padding-right: 25px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23ffffff' d='M2.293 4.293a1 1 0 0 1 1.414 0L6 6.586l2.293-2.293a1 1 0 1 1 1.414 1.414l-3 3a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 8px center;
  transition: all 0.3s ease;
}

.style-selector select:hover {
  background: rgba(255, 255, 255, 0.15);
  border-color: rgba(34, 211, 238, 0.3);
  box-shadow: 0 0 15px rgba(34, 211, 238, 0.15);
}

.style-selector select option {
  background-color: rgb(15, 23, 42);
  color: white;
  padding: 8px;
}

.style-selector select:focus {
  border-color: #22D3EE;
  box-shadow: 0 0 0 2px rgba(34, 211, 238, 0.2);
}

@-moz-document url-prefix() {
  .style-selector select {
    background-color: rgba(15, 23, 42, 0.95);
    color: white;
  }
  
  .style-selector select option {
    background-color: rgb(15, 23, 42);
  }
}

.visualizer {
  width: 100%;
  height: calc(100% - 70px);
  display: block;
  margin-top: 10px;
}

.progress-bar {
  flex: 1;
  min-width: 0;
  position: relative;
}

.progress-track {
  height: 4px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  position: relative;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background: linear-gradient(90deg, #22D3EE, #818CF8);
  border-radius: 2px;
  position: relative;
  transition: width 0.1s linear;
  box-shadow: 0 0 10px rgba(34, 211, 238, 0.2);
}

.time-display {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #fff;
  font-size: 12px;
  font-family: 'JetBrains Mono', monospace;
  margin: 0 20px;
  background: rgba(15, 23, 42, 0.8);
  padding: 2px 6px;
  border-radius: 4px;
  z-index: 10;
  opacity: 1;
  text-shadow: 0 0 2px rgba(255, 255, 255, 0.5);
  font-weight: 500;
  color: #22D3EE;
  transition: all 0.3s ease;
}

.is-playing .time-display {
  background: rgba(15, 23, 42, 0.9);
  box-shadow: 0 0 15px rgba(34, 211, 238, 0.15);
  text-shadow: 0 0 8px rgba(34, 211, 238, 0.5);
}
</style>

<script>
export default {
  name: 'AudioVisualizer',
  props: {
    analyserNode: {
      required: false,
      default: null
    },
    isPlaying: {
      type: Boolean,
      default: false
    },
    currentTime: {
      type: Number,
      default: 0
    },
    duration: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      animationFrame: null,
      canvas: null,
      ctx: null,
      dataArray: null,
      bufferLength: null,
      currentStyle: 'bars',
      time: 0,
      hue: 220,
      actualDuration: 0,
      visualStyles: [
        { value: 'bars', label: 'Bars' },
        { value: 'energy', label: 'Energy' }
      ]
    }
  },
  watch: {
    duration: {
      handler(newVal) {
        if (!this.isPlaying) {
          this.actualDuration = newVal
        }
      },
      immediate: true
    },
    analyserNode: {
      handler(newAnalyser) {
        if (newAnalyser) {
          this.setupAnalyser()
        }
      },
      immediate: true
    },
    isPlaying(newVal) {
      if (newVal) {
        this.startVisualization()
      } else {
        this.stopVisualization()
        this.actualDuration = this.duration
      }
    }
  },
  mounted() {
    this.initCanvas()
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
    this.stopVisualization()
  },
  methods: {
    initCanvas() {
      this.canvas = this.$refs.canvas
      this.ctx = this.canvas.getContext('2d')
      this.handleResize()
    },
    handleResize() {
      if (!this.canvas) return
      const container = this.canvas.parentElement
      const width = container.clientWidth
      const height = container.clientHeight
      
      this.canvas.width = width * window.devicePixelRatio
      this.canvas.height = height * window.devicePixelRatio
      this.ctx.scale(window.devicePixelRatio, window.devicePixelRatio)
    },
    setupAnalyser() {
      if (!this.analyserNode) return
      this.bufferLength = this.analyserNode.frequencyBinCount
      this.dataArray = new Uint8Array(this.bufferLength)
    },
    startVisualization() {
      if (!this.analyserNode) return
      this.draw()
    },
    stopVisualization() {
      if (this.animationFrame) {
        cancelAnimationFrame(this.animationFrame)
        this.animationFrame = null
      }
    },
    draw() {
      if (!this.analyserNode || !this.isPlaying || !this.canvas || !this.ctx) {
        this.stopVisualization()
        return
      }

      const width = this.canvas.width / window.devicePixelRatio
      const height = this.canvas.height / window.devicePixelRatio

      // 使用深色背景
      this.ctx.fillStyle = 'rgba(10, 15, 30, 0.2)'
      this.ctx.fillRect(0, 0, width, height)

      switch (this.currentStyle) {
        case 'bars':
          this.drawBars(width, height)
          break
        case 'energy':
          this.drawEnergy(width, height)
          break
      }

      this.animationFrame = requestAnimationFrame(() => this.draw())
    },
    drawBars(width, height) {
      if (!this.analyserNode) return
      this.analyserNode.getByteFrequencyData(this.dataArray)

      const barWidth = width / this.bufferLength * 2.5
      let x = 0

      for (let i = 0; i < this.bufferLength; i++) {
        const barHeight = (this.dataArray[i] / 255) * height
        const hue = (this.hue + i * 0.5) % 360
        this.ctx.fillStyle = 'hsla(' + hue + ', 70%, 20%, 0.8)'
        this.ctx.fillRect(x, height - barHeight, barWidth, barHeight)
        
        // 添加渐变效果
        const gradient = this.ctx.createLinearGradient(x, height - barHeight, x, height)
        gradient.addColorStop(0, 'hsla(' + hue + ', 70%, 30%, 0.1)')
        gradient.addColorStop(1, 'hsla(' + hue + ', 70%, 10%, 0.8)')
        this.ctx.fillStyle = gradient
        this.ctx.fillRect(x, height - barHeight, barWidth, barHeight)
        
        x += barWidth + 1
      }

      this.hue = (this.hue + 0.2) % 360
    },
    drawEnergy(width, height) {
      const centerX = width / 2
      const centerY = height / 2
      
      const average = this.dataArray.reduce((a, b) => a + b) / this.bufferLength
      const intensity = average / 255
      
      const gradient = this.ctx.createRadialGradient(
        centerX, centerY, 0,
        centerX, centerY, Math.max(width, height) / 2
      )
      gradient.addColorStop(0, 'hsla(' + this.hue + ', 70%, 20%, ' + (intensity * 0.3) + ')')
      gradient.addColorStop(1, 'rgba(10, 15, 30, 0.95)')
      this.ctx.fillStyle = gradient
      this.ctx.fillRect(0, 0, width, height)
      
      const maxRadius = Math.min(width, height) * 0.4
      const layers = 3
      
      for (let layer = 0; layer < layers; layer++) {
        this.ctx.beginPath()
        
        for (let i = 0; i < this.bufferLength; i++) {
          const value = this.dataArray[i] / 255
          const angle = (i / this.bufferLength) * Math.PI * 2
          const baseRadius = maxRadius * (0.5 + layer * 0.2)
          const radiusOffset = Math.sin(this.time * 3 + layer * Math.PI / 2) * 20
          const waveOffset = Math.sin(angle * 8 + this.time * 5) * 15 * value
          const radius = baseRadius + radiusOffset + waveOffset
          
          const x = centerX + Math.cos(angle) * radius
          const y = centerY + Math.sin(angle) * radius
          
          if (i === 0) {
            this.ctx.moveTo(x, y)
          } else {
            this.ctx.lineTo(x, y)
          }
        }
        
        this.ctx.closePath()
        
        this.ctx.strokeStyle = 'hsla(' + (this.hue + layer * 20) + ', 70%, 20%, ' + (intensity * 0.5) + ')'
        this.ctx.lineWidth = 2
        this.ctx.stroke()
        
        const fillGradient = this.ctx.createRadialGradient(
          centerX, centerY, 0,
          centerX, centerY, maxRadius
        )
        fillGradient.addColorStop(0, 'hsla(' + (this.hue + layer * 20) + ', 70%, 20%, ' + (intensity * 0.1) + ')')
        fillGradient.addColorStop(1, 'hsla(' + (this.hue + layer * 20) + ', 70%, 10%, 0)')
        this.ctx.fillStyle = fillGradient
        this.ctx.fill()
      }
      
      const coreRadius = maxRadius * 0.2 * (0.8 + Math.sin(this.time * 4) * 0.2)
      this.ctx.beginPath()
      this.ctx.arc(centerX, centerY, coreRadius, 0, Math.PI * 2)
      const coreGradient = this.ctx.createRadialGradient(
        centerX, centerY, 0,
        centerX, centerY, coreRadius
      )
      coreGradient.addColorStop(0, 'hsla(' + this.hue + ', 70%, 30%, ' + (intensity * 0.8) + ')')
      coreGradient.addColorStop(1, 'hsla(' + this.hue + ', 70%, 10%, 0)')
      this.ctx.fillStyle = coreGradient
      this.ctx.fill()
      
      this.time += 0.016
    },
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60)
      const remainingSeconds = Math.floor(seconds % 60)
      return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`
    }
  }
}
</script> 