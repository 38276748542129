import { render, staticRenderFns } from "./AudioTrack.vue?vue&type=template&id=5c65d592&scoped=true"
import script from "./AudioTrack.vue?vue&type=script&lang=js"
export * from "./AudioTrack.vue?vue&type=script&lang=js"
import style0 from "./AudioTrack.vue?vue&type=style&index=0&id=5c65d592&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c65d592",
  null
  
)

export default component.exports