<template>
  <div class="audio-controls">
    <div class="controls-section">
      <span class="label">Duration:</span>
      <el-input-number
        v-model="localDuration"
        :min="1"
        :max="3600"
        :step="1"
        @change="handleDurationChange"
      />
      <span class="unit">seconds</span>
      <div class="duration-presets">
        <el-button 
          size="mini" 
          @click="setDuration(600)"
          :class="{ active: localDuration === 600 }"
        >
          10 mins
        </el-button>
        <el-button 
          size="mini" 
          @click="setDuration(1800)"
          :class="{ active: localDuration === 1800 }"
        >
          30 mins
        </el-button>
        <el-button 
          size="mini" 
          @click="setDuration(3600)"
          :class="{ active: localDuration === 3600 }"
        >
          1 hour
        </el-button>
      </div>
      <span v-if="durationChanged" class="duration-warning">
        <i class="el-icon-warning-outline"></i>
        Duration changed, please synthesize again
      </span>
    </div>

    <div class="controls-section" style="display: none">
      <span class="label">Export Format:</span>
      <el-radio-group v-model="localExportFormat" @change="handleFormatChange">
        <el-radio label="mp3">MP3</el-radio>
        <el-radio label="wav">WAV</el-radio>
      </el-radio-group>
    </div>

    <div class="controls-section">
      <el-button
        type="primary"
        :disabled="isSynthesizing"
        @click="$emit('synthesize')"
      >
        Synthesize
      </el-button>
      
      <el-button
        type="success"
        :disabled="!synthesizedBuffer || durationChanged"
        @click="$emit('preview')"
      >
        {{ isPlaying ? 'Stop' : 'Preview' }}
      </el-button>
      
      <el-button
        type="warning"
        :disabled="!synthesizedBuffer || durationChanged"
        @click="$emit('export')"
      >
        Export
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AudioControls',
  
  props: {
    hasAnyTracks: {
      type: Boolean,
      default: false
    },
    synthesizedBuffer: {
      default: null
    },
    isSynthesizing: {
      type: Boolean,
      default: false
    },
    isPlaying: {
      type: Boolean,
      default: false
    },
    currentTime: {
      type: Number,
      default: 0
    },
    duration: {
      type: Number,
      default: 120
    },
    exportFormat: {
      type: String,
      default: 'mp3'
    }
  },

  data() {
    return {
      localDuration: this.duration,
      localExportFormat: this.exportFormat,
      durationChanged: false
    }
  },

  watch: {
    duration(val) {
      this.localDuration = val
    },
    exportFormat(val) {
      this.localExportFormat = val
    },
    synthesizedBuffer(newVal) {
      if (newVal) {
        this.durationChanged = false
      }
    }
  },

  methods: {
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60)
      const remainingSeconds = Math.floor(seconds % 60)
      return minutes + ':' + remainingSeconds.toString().padStart(2, '0')
    },

    handleDurationChange(value) {
      this.durationChanged = true
      this.$emit('update:duration', value)
    },

    handleFormatChange(value) {
      this.$emit('update:export-format', value)
    },

    setDuration(seconds) {
      this.localDuration = seconds
      this.durationChanged = true
      this.$emit('update:duration', seconds)
    }
  }
}
</script>

<style scoped>
.audio-controls {
  background: rgba(15, 23, 42, 0.3);
  border-radius: 12px;
  border: 1px solid rgba(34, 211, 238, 0.1);
  box-shadow: 0 4px 20px rgba(34, 211, 238, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.controls-section {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 10px;
  background: rgba(30, 41, 59, 0.4);
  border-radius: 8px;
  border: 1px solid rgba(148, 163, 184, 0.1);
}

.label {
  color: #94A3B8;
  font-size: 14px;
  min-width: 100px;
}

:deep(.el-button-group) {
  display: flex;
  gap: 1px;
}

:deep(.el-button) {
  background: transparent;
  border-color: rgba(34, 211, 238, 0.2);
  color: #94A3B8;
  font-family: 'JetBrains Mono', monospace;
  transition: all 0.3s ease;
}

:deep(.el-button:hover:not(:disabled)) {
  background: rgba(34, 211, 238, 0.1);
  border-color: rgba(34, 211, 238, 0.3);
  color: #22D3EE;
  transform: translateY(-1px);
  box-shadow: 0 5px 15px rgba(34, 211, 238, 0.2);
}

:deep(.el-button:active:not(:disabled)) {
  transform: translateY(0);
}

:deep(.el-button:disabled) {
  background: transparent;
  border-color: rgba(34, 211, 238, 0.1);
  color: rgba(148, 163, 184, 0.5);
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

:deep(.el-input-number) {
  background: transparent;
  border-color: rgba(34, 211, 238, 0.2);
}

:deep(.el-input-number__decrease),
:deep(.el-input-number__increase) {
  background: transparent;
  border-color: rgba(34, 211, 238, 0.2);
  color: #94A3B8;
}

:deep(.el-input-number__decrease:hover),
:deep(.el-input-number__increase:hover) {
  color: #22D3EE;
}

:deep(.el-input-number .el-input__inner) {
  color: #94A3B8;
  background: transparent;
}

:deep(.el-radio) {
  margin-right: 20px;
}

:deep(.el-radio__label) {
  color: #94A3B8;
}

:deep(.el-radio__input.is-checked + .el-radio__label) {
  color: #22D3EE;
}

:deep(.el-radio__input.is-checked .el-radio__inner) {
  background: #22D3EE;
  border-color: #22D3EE;
}

:deep(.el-radio__inner) {
  background: transparent;
  border-color: rgba(34, 211, 238, 0.2);
}

:deep(.el-radio__inner:hover) {
  border-color: #22D3EE;
}

.duration-warning {
  color: #F59E0B;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: 10px;
  background: rgba(245, 158, 11, 0.1);
  padding: 4px 8px;
  border-radius: 4px;
  animation: fadeIn 0.3s ease;
}

.duration-warning i {
  font-size: 14px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.duration-presets {
  display: flex;
  gap: 8px;
  margin-left: 15px;
}

:deep(.el-button--mini) {
  background: transparent;
  border-color: rgba(34, 211, 238, 0.2);
  color: #94A3B8;
  padding: 4px 12px;
  font-size: 12px;
  height: 24px;
  line-height: 1;
}

:deep(.el-button--mini:hover) {
  background: rgba(34, 211, 238, 0.1);
  border-color: rgba(34, 211, 238, 0.3);
  color: #22D3EE;
}

:deep(.el-button--mini.active) {
  background: rgba(34, 211, 238, 0.15);
  border-color: rgba(34, 211, 238, 0.4);
  color: #22D3EE;
  box-shadow: 0 0 10px rgba(34, 211, 238, 0.2);
}
</style> 