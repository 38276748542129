<template>
  <div class="audio-track">
    <div class="track-info">
      <div class="track-name">{{ track.name }}</div>
      <div class="track-details">
        <span class="track-duration">{{ formatDuration }}</span>
        <span class="track-size" v-if="track.file">{{ formatSize }}</span>
      </div>
    </div>
    <div class="track-controls">
      <el-slider
        v-model="localVolume"
        :min="0"
        :max="1"
        :step="0.01"
        @input="updateVolume"
      ></el-slider>
      <el-button
        type="danger"
        icon="el-icon-delete"
        circle
        @click="$emit('remove', track.id)"
      ></el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AudioTrack',
  
  props: {
    track: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      localVolume: this.track.volume
    }
  },

  computed: {
    formatDuration() {
      if (!this.track.buffer) return '0:00'
      const duration = this.track.buffer.duration
      const minutes = Math.floor(duration / 60)
      const seconds = Math.floor(duration % 60)
      return minutes + ':' + seconds.toString().padStart(2, '0')
    },

    formatSize() {
      if (!this.track.file) return ''
      const bytes = this.track.file.size
      if (bytes === 0) return '0 B'
      const k = 1024
      const sizes = ['B', 'KB', 'MB', 'GB']
      const i = Math.floor(Math.log(bytes) / Math.log(k))
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
    }
  },

  watch: {
    'track.volume'(newVal) {
      this.localVolume = newVal
    }
  },

  methods: {
    updateVolume(value) {
      this.$emit('update:volume', value)
    }
  }
}
</script>

<style scoped>
.audio-track {
  background: rgba(30, 41, 59, 0.4);
  border-radius: 8px;
  padding: 15px;
  display: flex;
  gap: 20px;
  align-items: center;
  border: 1px solid rgba(34, 211, 238, 0.1);
  transition: all 0.3s ease;
}

.audio-track:hover {
  background: rgba(30, 41, 59, 0.6);
  border-color: rgba(34, 211, 238, 0.2);
  transform: translateY(-1px);
  box-shadow: 0 4px 20px rgba(34, 211, 238, 0.1);
}

.track-info {
  flex: 1;
  min-width: 0;
}

.track-name {
  color: #E2E8F0;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.track-details {
  display: flex;
  gap: 15px;
  font-size: 12px;
  color: #94A3B8;
}

.track-controls {
  display: flex;
  align-items: center;
  gap: 15px;
  min-width: 200px;
}

:deep(.el-slider) {
  flex: 1;
  margin-right: 10px;
}

:deep(.el-slider__runway) {
  background-color: rgba(15, 23, 42, 0.3);
}

:deep(.el-slider__bar) {
  background-color: #22D3EE;
}

:deep(.el-slider__button) {
  border-color: #22D3EE;
  background-color: #22D3EE;
}

:deep(.el-slider__button:hover) {
  transform: scale(1.2);
}

:deep(.el-button.is-circle) {
  padding: 8px;
}

:deep(.el-button--danger) {
  background: transparent;
  border-color: rgba(244, 63, 94, 0.2);
  color: #F43F5E;
}

:deep(.el-button--danger:hover) {
  background: rgba(244, 63, 94, 0.1);
  border-color: rgba(244, 63, 94, 0.3);
  color: #FB7185;
}

:deep(.el-button--danger:active) {
  background: rgba(244, 63, 94, 0.2);
}
</style> 