<template>
  <div class="audio-synthesizer">
    <div class="track-sections">
      <!-- Main Audio Track -->
      <div class="track-section">
        <h2>Main Audio Track</h2>
        <div class="timeline-info" v-if="mainTracks.length > 0">
          <div class="timeline-header">
            <span>Main Track Timeline</span>
            <el-button type="text" size="mini" @click="copyTimeline('main')">
              <i class="el-icon-document-copy"></i> Copy
            </el-button>
          </div>
          <div class="timeline-content">
            <div 
              v-for="(time, index) in mainTrackTimeline" 
              :key="index" 
              class="timeline-item"
              :class="{ 'playing': isTimeInRange(currentTime, time.start, time.start + time.duration) }"
            >
              {{ formatTime(time.start) }} --> {{ formatTime(time.start + time.duration) }} | {{ formatFileName(time.name) }}
            </div>
          </div>
        </div>
        <AudioTrack
          v-for="track in mainTracks"
          :key="track.id"
          :track="track"
          @remove="removeTrack"
          @update:volume="updateTrackVolume(track.id, $event)"
        />
        <el-upload
          class="upload-demo"
          action="#"
          multiple
          :auto-upload="false"
          :on-change="file => handleFileChange(file, 'main')"
          :show-file-list="false"
          accept="audio/mpeg,audio/wav,audio/mp3"
        >
          <el-button type="primary">Add Main Audio</el-button>
          <div slot="tip" class="el-upload__tip">Supports MP3, WAV format audio files</div>
        </el-upload>
      </div>

      <!-- Auxiliary Audio Track -->
      <div class="track-section">
        <h2>Auxiliary Audio Track</h2>
        <div class="timeline-info" v-if="auxTracks.length > 0">
          <div class="timeline-header">
            <span>Background Track Timeline</span>
            <el-button type="text" size="mini" @click="copyTimeline('aux')">
              <i class="el-icon-document-copy"></i> Copy
            </el-button>
          </div>
          <div class="timeline-content">
            <div v-for="(time, index) in auxTrackTimeline" :key="index" class="timeline-item">
              {{ formatTime(time.start) }} --> {{ formatTime(time.start + time.duration) }} | {{ formatFileName(time.name) }}
            </div>
          </div>
        </div>
        <AudioTrack
          v-for="track in auxTracks"
          :key="track.id"
          :track="track"
          @remove="removeTrack"
          @update:volume="updateTrackVolume(track.id, $event)"
        />
        <el-upload
          class="upload-demo"
          action="#"
          multiple
          :auto-upload="false"
          :on-change="file => handleFileChange(file, 'auxiliary')"
          :show-file-list="false"
          accept="audio/mpeg,audio/wav,audio/mp3"
        >
          <el-button type="primary">Add Auxiliary Audio</el-button>
          <div slot="tip" class="el-upload__tip">Supports MP3, WAV format audio files</div>
        </el-upload>
      </div>
    </div>
  </div>
</template>

<script>
import AudioTrack from './AudioTrack.vue'

export default {
  name: 'AudioSynthesizer',
  
  components: {
    AudioTrack
  },

  props: {
    currentTime: {
      type: Number,
      default: 0
    },
    isPlaying: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      mainTracks: [],
      auxTracks: [],
      mainTimeline: [],
      auxTimeline: []
    }
  },

  computed: {
    mainTrackTimeline() {
      return this.mainTimeline
    },
    
    auxTrackTimeline() {
      return this.auxTimeline
    }
  },

  methods: {
    async handleFileChange(file, type) {
      try {
        const audioFile = file.raw
        const arrayBuffer = await audioFile.arrayBuffer()
        const audioContext = new (window.AudioContext || window.webkitAudioContext)()
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer)
        
        const track = {
          id: Date.now(),
          name: file.name,
          file: audioFile,
          buffer: audioBuffer,
          volume: 1,
          type: type
        }

        if (type === 'main') {
          this.mainTracks.push(track)
        } else {
          this.auxTracks.push(track)
        }

        this.$emit('add-track', track)
        
        this.$message.success('Audio file added successfully')
      } catch (error) {
        console.error('Error loading audio file:', error)
        this.$message.error('Failed to load audio file')
      }
    },

    removeTrack(trackId) {
      // Remove from main tracks
      const mainIndex = this.mainTracks.findIndex(t => t.id === trackId)
      if (mainIndex !== -1) {
        this.mainTracks.splice(mainIndex, 1)
        this.$emit('remove-track', trackId)
        return
      }

      // Remove from auxiliary tracks
      const auxIndex = this.auxTracks.findIndex(t => t.id === trackId)
      if (auxIndex !== -1) {
        this.auxTracks.splice(auxIndex, 1)
        this.$emit('remove-track', trackId)
      }
    },

    updateTrackVolume(trackId, volume) {
      const updateTrackInList = (tracks) => {
        const track = tracks.find(t => t.id === trackId)
        if (track) {
          track.volume = volume
          this.$emit('update-track', trackId, { ...track })
        }
      }

      updateTrackInList(this.mainTracks)
      updateTrackInList(this.auxTracks)
    },

    formatTime(seconds) {
      const hours = Math.floor(seconds / 3600)
      const minutes = Math.floor((seconds % 3600) / 60)
      const secs = Math.floor(seconds % 60)
      const ms = Math.floor((seconds % 1) * 1000)
      
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}.${ms.toString().padStart(3, '0')}`
    },
    
    formatFileName(name) {
      return name.split('\\').pop().split('/').pop()
    },
    
    copyTimeline(type) {
      const timeline = type === 'main' ? this.mainTrackTimeline : this.auxTrackTimeline
      const title = type === 'main' ? 'Main Track Timeline:' : 'Background Track Timeline:'
      
      const totalDuration = this.$parent.duration
      const header = `${title}\nTotal Duration: ${this.formatTime(totalDuration)}\n`
      
      const text = timeline.map(time => {
        const endTime = time.start + time.duration
        return `${this.formatTime(time.start)} --> ${this.formatTime(endTime)} | ${this.formatFileName(time.name)}`
      }).join('\n')
      
      const fullText = `${header}\n${text}`
      
      navigator.clipboard.writeText(fullText).then(() => {
        this.$message.success('Timeline copied to clipboard')
      }).catch(() => {
        this.$message.error('Failed to copy timeline')
      })
    },

    updateTimelines(totalDuration) {
      // 更新主音轨时间轴
      this.mainTimeline = []
      let currentTime = 0
      
      while (currentTime < totalDuration) {
        for (const track of this.mainTracks) {
          if (currentTime >= totalDuration) break
          
          this.mainTimeline.push({
            start: currentTime,
            name: track.name,
            duration: track.buffer.duration
          })
          
          currentTime += track.buffer.duration
        }
      }
      
      // 更新副音轨时间轴
      this.auxTimeline = []
      this.auxTracks.forEach(track => {
        let auxCurrentTime = 0
        while (auxCurrentTime < totalDuration) {
          this.auxTimeline.push({
            start: auxCurrentTime,
            name: track.name,
            duration: track.buffer.duration
          })
          auxCurrentTime += track.buffer.duration
        }
      })
      
      // 按开始时间排序副音轨时间轴
      this.auxTimeline.sort((a, b) => a.start - b.start)
    },

    isTimeInRange(currentTime, start, end) {
      return this.isPlaying && currentTime >= start && currentTime < end
    }
  }
}
</script>

<style scoped>
.audio-synthesizer {
  margin-bottom: 30px;
}

.track-sections {
  display: flex;
  gap: 30px;
  margin-bottom: 20px;
}

.track-section {
  flex: 1;
  padding: 25px;
  background: rgba(30, 41, 59, 0.8);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(34, 211, 238, 0.1);
  border: 1px solid rgba(34, 211, 238, 0.1);
  transition: all 0.3s ease;
}

.track-section:hover {
  box-shadow: 0 8px 30px rgba(34, 211, 238, 0.2);
  border-color: rgba(34, 211, 238, 0.2);
  transform: translateY(-2px);
}

.track-section h2 {
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  text-shadow: 0 0 10px rgba(34, 211, 238, 0.3);
  background: linear-gradient(135deg, #22D3EE, #818CF8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.el-upload {
  margin-top: 15px;
  width: 100%;
}

.el-upload__tip {
  margin-top: 5px;
  color: #94A3B8;
}

:deep(.el-upload .el-button) {
  width: 100%;
  background: linear-gradient(135deg, rgba(34, 211, 238, 0.15), rgba(99, 102, 241, 0.15));
  border: 1px solid rgba(34, 211, 238, 0.2);
  color: #22D3EE;
  padding: 12px 24px;
  font-size: 14px;
  letter-spacing: 0.5px;
  border-radius: 8px;
  backdrop-filter: blur(8px);
  text-shadow: 0 0 10px rgba(34, 211, 238, 0.3);
  position: relative;
  overflow: hidden;
}

:deep(.el-upload .el-button:hover) {
  background: linear-gradient(135deg, rgba(34, 211, 238, 0.2), rgba(99, 102, 241, 0.2));
  border-color: rgba(34, 211, 238, 0.3);
  transform: translateY(-1px);
  box-shadow: 0 0 20px rgba(34, 211, 238, 0.2),
              inset 0 0 20px rgba(34, 211, 238, 0.1);
}

:deep(.el-upload .el-button:active) {
  transform: translateY(1px);
  box-shadow: 0 0 15px rgba(34, 211, 238, 0.15),
              inset 0 0 10px rgba(34, 211, 238, 0.05);
}

:deep(.el-upload .el-button::before) {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    to bottom right,
    rgba(34, 211, 238, 0),
    rgba(34, 211, 238, 0.15),
    rgba(34, 211, 238, 0)
  );
  transform: rotate(45deg);
  animation: buttonShine 4s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  pointer-events: none;
}

@keyframes buttonShine {
  from {
    transform: rotate(45deg) translateX(-200%);
  }
  to {
    transform: rotate(45deg) translateX(200%);
  }
}

@media (max-width: 768px) {
  .track-sections {
    flex-direction: column;
  }

  .track-section {
    width: 100%;
  }
}

.timeline-info {
  background: rgba(15, 23, 42, 0.5);
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid rgba(34, 211, 238, 0.1);
  max-height: 120px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(34, 211, 238, 0.3) transparent;
}

.timeline-info::-webkit-scrollbar {
  width: 6px;
}

.timeline-info::-webkit-scrollbar-track {
  background: transparent;
}

.timeline-info::-webkit-scrollbar-thumb {
  background-color: rgba(34, 211, 238, 0.3);
  border-radius: 3px;
}

.timeline-info::-webkit-scrollbar-thumb:hover {
  background-color: rgba(34, 211, 238, 0.5);
}

.timeline-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  color: #22D3EE;
  font-size: 14px;
  font-weight: 500;
}

.timeline-content {
  font-family: 'JetBrains Mono', monospace;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
  padding-right: 4px;
}

.timeline-item {
  padding: 4px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Consolas', 'JetBrains Mono', monospace;
  border-bottom: 1px solid rgba(34, 211, 238, 0.1);
  transition: all 0.3s ease;
}

.timeline-item:last-child {
  border-bottom: none;
}

.timeline-item.playing {
  background: rgba(34, 211, 238, 0.15);
  color: #fff;
  padding-left: 8px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(34, 211, 238, 0.2);
  border-left: 2px solid #22D3EE;
}

:deep(.el-button--text) {
  color: #22D3EE;
  padding: 0;
}

:deep(.el-button--text:hover) {
  color: #0EA5E9;
}
</style> 